#dashboard-app {
  background-color: #FAFAFA;

  #dashboard {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    #dashboard-content {
      flex-grow: 1;

      #dashboard-page {
        margin-top: 10px;
        padding: 20px;
      }

      .tile {
        position: relative;
        padding: 10px;
        border: 1px solid $indicator-light-gray;
        background: white;

        .top-right-tool {
          flex-grow: 2;
          text-align: right;

          > .tool-content {
            justify-content: flex-end;
          }
        }
      }
    }
  }
}