// libs
@import 'leaflet/dist/leaflet.css';
@import '@fortawesome/fontawesome-free/css/all.css';
@import '~bootstrap/dist/css/bootstrap.min.css';


// variables
@import 'variables';


// components
@import 'dashboard';
