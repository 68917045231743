.plotly-indicator {
  background: white;
  width: 100%;
  height: 200px;

  &.loading {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #ffffff;
    background: linear-gradient(to right, #ffffff 8%, #f3f3f3 38%, #ffffff 54%);
    background-size: 1000px 640px;

    @keyframes placeHolderShimmer{
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

    > div {
      display: none;
    }
  }
}