.login-form-container {
  height: 100vh;
  background: $fillup-gradient;
  margin: 0;

  label {
    color: white;
  }

  .reset-password-link {
    color: white;
    text-decoration: none;
    margin-left: 10px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.reset-password {
  height: 100vh;
  padding: 30px;
  background: $fillup-gradient;
}