.indicator {
  margin-bottom: 20px;

  &.number-evolution {
    &:not(.flat) {
      aspect-ratio: 1.3;
    }

    > .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .legend {
        color: $indicator-dark-gray;
        font-size: 1.2rem;
        margin-bottom: 20px;

        @media screen and (min-width: 1000px) {
          font-size: 1.5rem;
        }
      }

      .values {
        .currency {
          color: $indicator-light-gray;
          margin-right: 5px;
        }
        .value {
          font-size: 1.1rem;
          margin-right: 10px;

          @media screen and (min-width: 700px) {
            font-size: 1.4rem;
          }

          @media screen and (min-width: 1000px) {
            font-size: 1.8rem;
          }
        }
      }
    }
  }

  &.number-distribution {
    > .content {
      text-align: center;

      .legend {
        color: $indicator-dark-gray;
        font-size: 1.2rem;
        margin-bottom: 20px;

        @media screen and (min-width: 1000px) {
          font-size: 1.5rem;
        }
      }

      .charts {
        justify-content: space-between;

        .current-chart {
          flex-grow: 2;
        }
  
        .previous-chart {
          flex-grow: 1;

          canvas {
            width: 100%;
          }
        }
      }
    }
  }

  &.number-with-target {
    aspect-ratio: 1.3;

    > .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .legend {
        color: $indicator-dark-gray;
        font-size: 1.2rem;
        margin-bottom: 20px;

        @media screen and (min-width: 1000px) {
          font-size: 1.5rem;
        }
      }

      .values {
        .currency {
          color: $indicator-light-gray;
          margin-right: 5px;
        }
        .value {
          font-size: 1.1rem;
          margin-right: 10px;

          @media screen and (min-width: 700px) {
            font-size: 1.4rem;
          }

          @media screen and (min-width: 1000px) {
            font-size: 1.8rem;
          }
        }
      }

      .target-bar {
        height: 30px;
        border: 1px solid black;
        border-radius: 5px;
        background-color: #DEDEDE;

        &.complete {
          background-color: lightgreen;
        }

        > .value {
          height: 100%;
          border: none;
          border-radius: 5px;
          background-color: forestgreen;
        }
      }
    }
  }
}

.indicator-container {
  position: relative;

  .indicator-helper {
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 10;
    font-size: 1.5rem;
    color: $indicator-dark-gray;
  }
}

.table-objectives {
  > thead > tr > th {
    background-color: $fillup-blue-green;
  }

  th.dr-th {
    font-variant: small-caps;
  }
}