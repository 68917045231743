.navbar {
  background: linear-gradient(-20deg, $fillup-blue-green 0%, $fillup-yellow 100%);
  padding: 0 20px;

  .navbar-logo {
    height: 40px;
    margin-right: 30px;
  }

  li {
    a.nav-link {
      padding: 15px 10px;
      margin: 0 5px;
      color: black;

      &.router-link-active  {
        font-weight: bold;
        border-bottom: 2px solid #333;
      }

      text-decoration: none;
      color: #333;
    }
  }
}