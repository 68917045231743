table {
  .col-30 {
    width: 30%;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }
}