#dashboard-menu {
  min-height: 70px;
  padding: 10px 20px;
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #DEDEDE;

  .date-choice-type {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 23px;

    .unit-title {
      width: 60%;
      text-align: center;
      font-weight: bold;
      text-transform: capitalize;
      padding-top: 5px;
      font-size: 1.2rem;
    }
  }
}